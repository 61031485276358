import React, { useState } from 'react';
import './App.scss';
import TextEditor from './TextEditor';

function App() {
  const [text, setText] = useState(
    localStorage.getItem('text') ||
      `First One->Second One: this is the text
Second One-->Third One: blah
Third One->First One: back to start
Second One -> Fourth: blahsafd`
  );

  const sequences = parseText(text);

  return (
    <div className="App">
      <div className="editor">
        <TextEditor
          value={text}
          onChange={(e) => {
            setText(e.target.value);
            localStorage.setItem('text', e.target.value);
          }}
        />
      </div>
      <div className="diagram">
        <SequenceDiagram sequences={sequences} />
      </div>
    </div>
  );
}

function SequenceDiagram(props) {
  const { sequences } = props;
  const participants = getOrderedParticipants(sequences);
  const participantSvgs = [];

  let totalHeight = 0;
  let totalWidth = 0;

  if (sequences.length > 0) {
    const boxHeight = 40;
    const sequenceCoords = calculateSequenceCoordinates(sequences, boxHeight);
    const lastSequenceCoord = sequences.length > 0 ? sequenceCoords[sequences[sequences.length - 1].index] : null;
    const height = lastSequenceCoord.y + boxHeight + 40;
    totalHeight = height + 1;

    const participantsCoords = calculateParticipantsCoordinates(sequences, participants);
    const lastParticipantCoord = participantsCoords[participants[participants.length - 1].name];
    totalWidth = lastParticipantCoord.x + lastParticipantCoord.width;

    for (const participant of participants) {
      const participantCoords = participantsCoords[participant.name];
      participantSvgs.push(<ParticipantAndLifeline key={participant.name} participant={participant} coords={participantCoords} height={height} />);
    }

    for (const sequence of sequences) {
      const sourceParticipantCoords = participantsCoords[sequence.sourceParticipant];
      const destParticipantCoords = participantsCoords[sequence.destintationParticipant];
      const sequenceCoord = sequenceCoords[sequence.index];

      let x;
      let width;
      let direction;
      if (destParticipantCoords.x > sourceParticipantCoords.x) {
        direction = 'right';
        x = sourceParticipantCoords.centerX;
        width = destParticipantCoords.centerX - sourceParticipantCoords.centerX;
      } else {
        direction = 'left';
        x = destParticipantCoords.centerX;
        width = sourceParticipantCoords.centerX - destParticipantCoords.centerX;
      }

      participantSvgs.push(
        <SequenceArrowAndMessage
          key={sequence.index}
          message={sequence.message}
          lineStyle={sequence.lineStyle}
          width={width}
          height={height}
          x={x}
          y={sequenceCoord.y}
          direction={direction}
        />
      );
    }
  }

  return (
    <svg width={totalWidth} height={totalHeight} xmlns="http://www.w3.org/2000/svg">
      <g>{participantSvgs}</g>
    </svg>
  );
}

function parseText(text) {
  let sequences = [];

  if (text && text.length > 0) {
    let i = 0;
    for (const textLine of text.split(/\n/)) {
      const parsedLine = parseTextLine(textLine);
      if (!parsedLine.error) {
        parsedLine.index = i++;
        sequences.push(parsedLine);
      } else {
        console.log(parsedLine.error);
      }

      // // match regex
      // const regex = /(.+)(-->|->)(.+):(.+)/g;
      // const result = regex.exec(textLine);

      // console.log(parseTextLine(textLine));

      // if (result) {
      //   const arrowStyle = result[2];
      //   console.log(arrowStyle);
      //   sequences.push({
      //     index: sequences.length,
      //     sourceParticipant: result[1].trim(),
      //     destintationParticipant: result[3].trim(),
      //     message: result[4].trim(),
      //   });
      // }
    }
  }
  return sequences;
}

export function parseTextLine(textLine) {
  const result = { originalText: textLine };

  let destintationParticipantStartIndex = null;

  let arrowIndex = textLine.indexOf('-->');
  if (arrowIndex > -1) {
    result.lineStyle = 'dashed';
    destintationParticipantStartIndex = arrowIndex + 3;
  } else {
    arrowIndex = textLine.indexOf('->');
    if (arrowIndex > -1) {
      result.lineStyle = 'solid';
      destintationParticipantStartIndex = arrowIndex + 2;
    }
  }

  const colonIndex = textLine.indexOf(':', destintationParticipantStartIndex);

  if (arrowIndex === -1) {
    return { error: 'Bad syntax: missing arrow', originalText: textLine };
  }

  if (colonIndex === -1) {
    return { error: 'Bad syntax: missing colon', originalText: textLine };
  }

  result.sourceParticipant = textLine.substring(0, arrowIndex).trim();
  if (!result.sourceParticipant) {
    return { error: 'Bad syntax: missing source participant', originalText: textLine };
  }

  result.destintationParticipant = textLine.substring(destintationParticipantStartIndex, colonIndex).trim();
  if (!result.destintationParticipant) {
    return { error: 'Bad syntax: missing destintation participant', originalText: textLine };
  }

  result.message = textLine.substring(colonIndex + 1).trim();
  if (!result.message) {
    return { error: 'Bad syntax: missing message', originalText: textLine };
  }

  return result;
}

function getOrderedParticipants(sequences) {
  const participants = [];
  let index = 0;
  for (const sequence of sequences) {
    if (!participants.some((x) => x.name === sequence.sourceParticipant)) {
      participants.push({ name: sequence.sourceParticipant, index });
      index++;
    }
    if (!participants.some((x) => x.name === sequence.destintationParticipant)) {
      participants.push({ name: sequence.destintationParticipant, index });
      index++;
    }
  }
  return participants;
}

function calculateSequenceCoordinates(sequences, startY) {
  let coords = {};
  let y = startY;
  for (let i = 0; i < sequences.length; i++) {
    coords[sequences[i].index] = { y };

    y += 60;
  }
  return coords;
}

function calculateParticipantsCoordinates(sequences, participants) {
  const arrowSize = 10;
  const coords = {};
  let nextX = 5;

  for (const participant of participants) {
    const width = calculateParticipantWidth(participant);
    coords[participant.name] = { x: nextX, width };
    nextX += width + 60;
  }

  for (const sequence of sequences) {
    const textWidth = getTextWidth(sequence.message, '16px Arial') + 16;
    const spannedParticipants = getSpannedParticipants(sequence, participants);

    const rightParticipant = spannedParticipants[spannedParticipants.length - 1];
    const totalWidth = coords[rightParticipant.name].x - coords[spannedParticipants[0].name].x;
    const widthOffset = textWidth - (totalWidth - arrowSize * 2);
    if (widthOffset > 0) {
      for (let i = rightParticipant.index; i < participants.length; i++) {
        coords[participants[i].name].x += widthOffset;
      }
    }
  }

  for (const participant of participants) {
    const participantCoords = coords[participant.name];
    participantCoords.centerX = participantCoords.x + participantCoords.width / 2;
  }

  return coords;
}

function getSpannedParticipants(sequence, participants) {
  const spannedParticipants = [];
  const sourceParticipantIndex = participants.findIndex((x) => x.name === sequence.sourceParticipant);
  const destintationParticipantIndex = participants.findIndex((x) => x.name === sequence.destintationParticipant);
  for (let i = Math.min(sourceParticipantIndex, destintationParticipantIndex); i <= Math.max(sourceParticipantIndex, destintationParticipantIndex); i++) {
    spannedParticipants.push(participants[i]);
  }
  return spannedParticipants;
}

function calculateParticipantWidth(participant) {
  const width = getTextWidth(participant.name, '16px Arial');
  return width + 8;
}

function ParticipantAndLifeline(props) {
  const { participant, coords, height } = props;
  const { width, x } = coords;
  const boxHeight = 30;
  const textTop = 18;
  return (
    <svg x={x} y={1} width={width} height={height}>
      <text x={width / 2} y={textTop} width={width} height={boxHeight} textAnchor="middle">
        {participant.name}
      </text>
      <rect width={width} height={boxHeight} x="0" y="0" stroke="black" fill="transparent" />
      <line x1={width / 2} y1={boxHeight} x2={width / 2} y2={height - boxHeight} stroke="black" />
      <text x={width / 2} y={height - boxHeight + textTop} width={width} height={boxHeight} textAnchor="middle">
        {participant.name}
      </text>
      <rect width={width} height={boxHeight} x="0" y={height - boxHeight} stroke="black" fill="transparent" />
    </svg>
  );
}

function SequenceArrowAndMessage(props) {
  const { x, y, width, message, direction, lineStyle } = props;
  const lineY = 20;
  const arrowSize = 10;
  return (
    <svg x={x} y={y} width={width}>
      <text x={width / 2} y={16} width={width} height={40} textAnchor="middle" fill="black" fontSize={16}>
        {message}
      </text>
      <line x1={0} y1={lineY} x2={width} y2={lineY} stroke="black" strokeDasharray={lineStyle === 'dashed' ? '4 2' : '1 0'} />
      {direction === 'right' ? (
        <polygon points={`${width - arrowSize},${lineY - arrowSize / 2} ${width},${lineY} ${width - arrowSize},${lineY + arrowSize / 2}`} stroke="black" />
      ) : (
        <polygon points={`0,${lineY} ${arrowSize},${lineY - arrowSize / 2} ${arrowSize},${lineY + arrowSize / 2}`} stroke="black" />
      )}
      ;
    </svg>
  );
}

export function getTextWidth(text, font) {
  // re-use canvas object for better performance
  const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'));
  const context = canvas.getContext('2d');
  context.font = font;
  const metrics = context.measureText(text);
  return Math.ceil(metrics.width);
}

export default App;
