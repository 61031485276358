import React, { useRef, useLayoutEffect } from 'react';
import { parseTextLine } from './App';
import './TextEditor.scss';

export default function TextEditor(props) {
  const formattedEl = useRef(null);
  const textareaEl = useRef(null);

  useLayoutEffect(() => {
    const currentTextareaEl = textareaEl.current;
    const onInputScroll = () => {
      formattedEl.current.scrollLeft = textareaEl.current.scrollLeft;
      formattedEl.current.scrollTop = textareaEl.current.scrollTop;
    };

    currentTextareaEl.addEventListener('scroll', onInputScroll);

    return () => {
      currentTextareaEl.removeEventListener('scroll', onInputScroll);
    };
  }, [textareaEl, formattedEl]);

  const formattedLines = [];

  if (props.value && props.value.length > 0) {
    let i = 0;
    for (const textLine of props.value.split(/\n/)) {
      const formattedLine = [];
      const parsedTextLine = parseTextLine(textLine);

      if (!parsedTextLine.error) {
        const sourceIndex = textLine.indexOf(parsedTextLine.sourceParticipant);
        const destinationIndex = textLine.indexOf(parsedTextLine.destintationParticipant, sourceIndex + parsedTextLine.sourceParticipant.length);
        const messageIndex = textLine.indexOf(parsedTextLine.message, destinationIndex + parsedTextLine.destintationParticipant.length);

        formattedLine.push(textLine.substring(0, sourceIndex));
        formattedLine.push(
          <span key="source" className="source">
            {textLine.substring(sourceIndex, sourceIndex + parsedTextLine.sourceParticipant.length)}
          </span>
        );
        formattedLine.push(textLine.substring(sourceIndex + parsedTextLine.sourceParticipant.length, destinationIndex));
        formattedLine.push(
          <span key="dest" className="dest">
            {textLine.substring(destinationIndex, destinationIndex + parsedTextLine.destintationParticipant.length)}
          </span>
        );
        formattedLine.push(textLine.substring(destinationIndex + parsedTextLine.destintationParticipant.length, messageIndex));
        formattedLine.push(
          <span key="message" className="message">
            {parsedTextLine.message}
          </span>
        );
      } else {
        formattedLine.push(textLine);
      }

      formattedLine.push('\n');
      formattedLines.push(formattedLine);
    }
  }

  return (
    <div className="text-editor">
      <textarea className="text-editor-textarea" value={props.value} onChange={props.onChange} ref={textareaEl}></textarea>
      <div className="text-editor-formatted" ref={formattedEl}>
        {formattedLines}
      </div>
    </div>
  );
}
